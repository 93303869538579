// MAIN 404
function NoPage(){
    return(
        <body style={styles.body}>
            <h1>404 page not found</h1>

        </body>
    )
}

export default NoPage;

const styles={
    body:{
        textAlign: "center",
        paddingTop: 60,
    }
}