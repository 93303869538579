import Dropdown from 'react-bootstrap/Dropdown';

import { useTranslation } from "react-i18next";

import "./DropdownDisplay.css"
// MAIN
function DropdownDisplay(){
    const [t, i18n] = useTranslation("global")


    return(
        <div className="containerOuter container-fluid dropdown row pb-5 pt-2 ">
            
            <div className="col">
                <div className='containerInner mt-3 '>
                    <h3 className='px-3'>{t("services.women.title")}</h3>
                    <Dropdown.Item href="/prices">{t("services.women.item1")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.women.item2")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.women.item3")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.women.item4")}</Dropdown.Item>
                </div>
            </div>

            <div className="col">
                <div className='containerInner mt-3'>
                    <h3 className='px-3'>{t("services.men.title")}</h3>
                    <Dropdown.Item href="/prices">{t("services.men.item1")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.men.item2")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.men.item3")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.men.item4")}</Dropdown.Item>
                </div>
            </div>

            <div className="col">
                <div className='containerInner mt-3 text-break'>
                    <h3 className='px-3'>{t("services.children.title")}</h3>
                    <Dropdown.Item href="/prices">{t("services.children.item1")}</Dropdown.Item>
                    <Dropdown.Item href="/prices">{t("services.children.item2")}</Dropdown.Item>
                </div>
            </div>

            

                
         
        </div>
    )
}

export default DropdownDisplay;

// add text width limiting with kicking text down into another line
// add fadein animation for dropdown box