// arraying images
import Example1 from "../images/Examples/Example1.jpg"
import Example2 from "../images/Examples/Example2.jpg"
import Example3 from "../images/Examples/Example3.jpg"
import Example4 from "../images/Examples/Example4.jpg"
import Example5 from "../images/Examples/Example5.jpg"
import Example6 from "../images/Examples/Example6.jpg"
import Example8 from "../images/Examples/Example8.jpg"
import Example10 from "../images/Examples/Example10.jpg"

export default [
    Example1,
    Example2,
    Example3,
    Example4,
    Example5,
    Example6,
    Example8,
    Example10

]