import "./pageStyles/Home.css"
import { useEffect, useState } from 'react';
import { motion, transform } from "framer-motion"; 

import {Facebook, Instagram, TelephoneFill, Tiktok} from 'react-bootstrap-icons';
import {At as Eta} from "react-bootstrap-icons"
import { ArrowLeft } from 'react-bootstrap-icons';
import exampleImg from '../config/fancyImages'  // images for the fancy conveyour display in an array
import cardLogo from "../images/card.png"

import OpeningTimesMongoDown from '../components/OpeningTimesMongoDown';

import { useTranslation } from "react-i18next";




    

// MAIN
function Home({weekDays}){
    const [t, i18n] = useTranslation("global")
    

    // opening-times animation code
    // useEffect(() =>{    // prevents page switching from disabling "scrollshow()"
    //     scrollshow(); 
    // }, [])
    // opening-times animation code

    // Fancy image track code
    useEffect(() =>{    // prevents "cannot read properties of null track.dataset" error by forcing "track" declaration on mount
        const track = document.getElementById("image-track")
        const arrow = document.getElementById("arrow")
        const handleOnDown = e =>{
            track.dataset.mouseDownAt = e.clientX;
            arrow.style = "width: 0";   // dumb, but works for disabling the arrow animation on screen click
        }
    
    
        const handleOnMove = e =>{
            if(track.dataset.mouseDownAt === "0") return;
            const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX;
            const maxDelta = window.innerWidth / 2;
            
            const percentage = (mouseDelta / maxDelta) * -100;
            const nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage
            const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
    
            track.dataset.percentage = nextPercentage;
    
            
            track.animate({
                transform: `translate(${nextPercentage}%, 0%)`
                }, { duration: 1200, fill: "forwards" });
    
            for(const image of track.getElementsByClassName("image")) {
                image.animate({
                    objectPosition: `${100 + nextPercentage *1.5}% center`
                }, { duration: 1200, fill: "forwards" });
                }
        }
    
        const handleOnUp = () =>{
            track.dataset.mouseDownAt = "0";
            track.dataset.prevPercentage = track.dataset.percentage;
        }

        
        
        

        window.onmousedown = e => handleOnDown(e);

        window.ontouchstart = e => handleOnDown(e.touches[0]);

        window.onmouseup = e => handleOnUp(e);

        window.ontouchend = e => handleOnUp(e.touches[0]);

        window.onmousemove = e => handleOnMove(e);

        window.ontouchmove = e => handleOnMove(e.touches[0]);
    }, [])
    
    // Fancy image track code

    return(
        <motion.body 
        style={styles.body}
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{x: window.innerWidth, opacity: 0, transition: {duration:  0.1}}}
        >
            <section className='bg-image'>
                <div className="text-center">
                    <h1 className="text-justify textContainer">
                        <span className="title a px-2">{t("title.a")} </span>
                        <span id="rolltext" className="title b px-2">{t("title.b")} </span>
                        <span className="title c px-2">{t("title.c")} </span>
                        <span className="title d px-2">{t("title.d")} </span>
                    </h1>
                </div>
                <div className="descriptionHome text-center container">
                    <p className="fs-5 ">
                        {t("title.subtitle")}
                    </p>
                    
                 
                </div>
            </section>   

            {/* Fancy image display */}
            <section className='fancy-track bg-dark pt-4'>
                <div id='image-track' className='' data-mouse-down-at="0" data-prev-percentage="0">
                    
                <div>

                    <ArrowLeft id="arrow" className="arrow" color='white' width={200} height={200}/>
                    </div>
                    {exampleImg.map((img, i) =>(
                        <img className='image' src={exampleImg[i]} width={1080} height={1349} draggable="false" key={i}/>
                       
                        
                    ))}
                </div>
            </section>


            {/* Opening times table and map*/}
            <section className="p-5 d-flex row bg-dark">
                {(typeof weekDays === "undefined") ? (
                    <OpeningTimesMongoDown />   // Default opening times, in case MongoDB fails to provide updated data. 
                ):(                             // Store same data in JSON locally as a backup(?)

                <div className="openingTimes">
                
                    <h3>{t("home.openingTimes")}</h3>

                    {weekDays.map((item, i) =>(
                        <motion.div 
                        className="dayTime" 
                        key={i} 
                        initial={{opacity: 0, translateY: "100%"}} 
                        whileInView={{opacity: 1, translateY: "0%"}} 
                        transition={{ delay: 0.09 * i }} 
                        viewport={{ once: true }} 
                        >
                            {item.start == 0 || item.end == 0 || item.end == "23:59"? 
                                (   <>
                                        <span className="day fs-4" style={{color: "red"}}>{t(`home.weekDays.${item.day}`)}</span>
                                        <span className="time fs-4" style={{color: "red"}}>{t("home.closed")}</span>
                                    </>
                                )
                                :(
                                    <>
                                    <span className="day fs-4">{t(`home.weekDays.${item.day}`)}</span>
                                    <span className="time fs-4">{item.start} - {item.end}</span>
                                    </>
                                )}
                         </motion.div>
                    ))}
                
                   
                </div>
                )}
                {/* map */}
                <div className="map-contacts row flex-grow-1 mx-0">
                    <div className='map-Container col pt-2'>
                        <iframe title="map" className="p-1 shadow bg-body-tertiary rounded align-self-center ratio" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d621.7626078170572!2d5.44750782001063!3d51.43886771117386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6d97050ce149b%3A0x63fc910ffd0c3505!2sZeelsterstraat%2036%2C%205652%20EK%20Eindhoven!5e0!3m2!1sen!2snl!4v1705854496692!5m2!1sen!2snl" width="500" height="400" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className='contact-logo-container col'>
                        <div className='logo-container' >
                            <img src={cardLogo} alt="..."className="img-fluid rounded-pill p-2 mx-3" width={200} height={200}/>
                        </div>

                        <div className='contact-container pt-4 fs-5'>
                            <div className=''>
                                <TelephoneFill id='phone-icon' color='black' className='' width={30}/>
                                <span className='contactInfo'>888-999-3334</span>
                            </div>
                            <div className='pt-3 overflow-x-visible text-nowrap'>
                                <Eta className='' width={30} size={40}/>
                                <span className='contactInfo'>KaperRenas@gmail.com</span>
                            </div>
                        </div>
                    </div>

                    <div className="socials-container pt-5 ">
                        <div 
                        id="Facebook"
                        className="social-item"
                        >
                        <div><Facebook className="m-2 " width="32" height="32"/></div>
                        <span className="fs-3 "><a href="https://www.tiktok.com/@r.e.n.a.s.1987" target="_blank">@r.e.n.a.s.1987</a></span>
                        </div>

                        <div 
                        id="Instagram"
                        className="social-item mt-2"
                        >
                        <div><Instagram className="m-2 " width="32" height="32"/></div>
                        <span className="fs-3"><a href="https://www.instagram.com/renas.barbershop1" target="_blank">renas.barbershop1</a></span>
                        </div>

                        <div 
                        id="TikTok"
                        className="social-item mt-2"
                        >
                        <div><Tiktok className="m-2 mb-5" width="32" height="32"/></div>
                        <span className="fs-3 "><a href="https://www.tiktok.com/@r.e.n.a.s.1987" target="_blank">@r.e.n.a.s.1987</a></span>
                        </div>

                    </div>
                </div>
            </section>


        </ motion.body>
    )
}

export default Home;

const styles={
    body:{
        paddingTop: 50,
    },
    text:{
        color: "red",
    }

}