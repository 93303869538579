import React, { useEffect } from 'react'
import { motion } from "framer-motion"; 

import { useTranslation } from "react-i18next";

// DUMB 
// Spammed framer-motion props to animate
// Fix later

function OpeningTimesMongoDown() {
    const [t, i18n] = useTranslation("global")

    // opening-times animation code
    // useEffect(() =>{
    //     scrollshow();
    // }, [])
    // opening-times animation code

  return (
    <div className="openingTimes">
        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 1 }} viewport={{ once: true }} >
            <span className="day fs-4" >{t("home.weekDays.Monday")}</span>
            <span className="time fs-4" >08:00 - 17:00</span>
        </motion.div >
        
        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 2 }} viewport={{ once: true }}>
            <span className="day fs-4" >{t("home.weekDays.Tuesday")}</span>
            <span className="time fs-4" >08:00 - 17:00</span>
        </motion.div >

        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 3 }} viewport={{ once: true }}>
            <span className="day fs-4" >{t("home.weekDays.Wednesday")}</span>
            <span className="time fs-4" >08:00 - 17:00</span>
        </motion.div >

        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 4 }} viewport={{ once: true }}>
            <span className="day fs-4" >{t("home.weekDays.Thursday")}</span>
            <span className="time fs-4" >08:00 - 17:00</span>
        </motion.div >

        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 5 }} viewport={{ once: true }}>
            <span className="day fs-4" >{t("home.weekDays.Friday")}</span>
            <span className="time fs-4" >08:00 - 17:00</span>
        </motion.div >

        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 6 }} viewport={{ once: true }}>
            <span className="day fs-4" style={{color: "red"}} >{t("home.weekDays.Saturday")}</span>
            <span className="time fs-4" style={{color: "red"}} >CLOSED</span>
        </motion.div >

        <motion.div  className='dayTime' initial={{opacity: 0, translateY: "100%"}} whileInView={{opacity: 1, translateY: "0%"}} transition={{ delay: 0.09 * 7 }} viewport={{ once: true }}>
            <span className="day fs-4" style={{color: "red"}} >{t("home.weekDays.Sunday")}</span>
            <span className="time fs-4" style={{color: "red"}} >CLOSED</span>
        </motion.div >
    </div>
  )
}

export default OpeningTimesMongoDown