import './App.css';
import React, {useEffect, useState} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";


import Footer from './components/Footer'
import AnimatedRoutes from './components/AnimatedRoutes';
//Test abloo bloo

function App() {
  const [weekDays, setWeekDays] = useState();

  // Mobile user touch-feedback code (Highlights text yellow for 0.5s)
  function handleTouch(event){
    event.target.classList.add("touchedItem")
    console.log(event.target)
  }

  useEffect(() =>{
    const allA = document.querySelectorAll("a")
    for(const item of allA){
      item.addEventListener("touchstart", handleTouch)
    }

  }, [])
  // Mobile user touch-feedback code
  

    useEffect(() =>{
        fetch("/api").then(
          response => response.json()
        ).then(
          data =>{
            setWeekDays(data)
            //console.log(data);
          }
        )
      }, [])
 

  return (
    // Defines routing behavior, is not displaying anything by itself
    <>
    <BrowserRouter>
      <AnimatedRoutes weekDays={weekDays}/>
    </BrowserRouter>

    <Footer />
    
    </>

    
    
  );
}

export default App;


// add automatic notification email sending with AWS

// Custom scrollbar? Look into bootstrap solution, might show up all wrong on phones tho.
// put placeholder number and address up high.

// Change default tab icon

// add a video?

// LAUNCHING: \server npm run dev (starts backend); \hairdresser npm start (starts frontend)
