import React, { useState } from 'react'
// require("dotenv").config();


function AdminLogin() {

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");


    async function loginFunc(e){
        e.preventDefault();

        // const request = await fetch("http://localhost:5000/api/login", {
        // const request = await fetch("http://179.61.246.224:5000/api/login", {
        // const request = await fetch("http://kestrelp.dev:5000/api/login", {  // Keeping all of the above for reference as to why it failed
        // const request = await fetch("https://kestrelp.dev/api/login", {      // Actually works online with this, unused because it doesn't work locally. It is in the servers .env file though.
        const request = await fetch(`${process.env.REACT_APP_LOGIN_API}`, {         
            method: "POST",
            headers:{
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                password,
            })
        }).catch((error) => {
            alert(`SERVER DOWN! ${error}`)
            window.location.href = "/";
        })

        const response = await request.json();

        if(response.user){
            sessionStorage.setItem("token", response.user)
            alert("Login success!")
            window.location.href = "/admin-panel"
        }
        else{
            alert("Wrong Login details!")
            setName("");
            setPassword("");
        }
    }

  return (
    <body>
        <h1 className='pt-5'>Login</h1>
        <form onSubmit={loginFunc}>
            <input type="text" placeholder="name" onChange={(e) => {setName(e.target.value)}} value={name}/>
            <input type="password" placeholder="password" onChange={(e) => {setPassword(e.target.value)}} value={password}/>
            <input type="submit" placeholder="Submit"/>
        </form>

        <div className='p-5'>
            <h3>Developer Contacts:</h3>
            <p>Contact in case you want changes made to the website.</p>
            <h5>Kestrel</h5>
            <h5>soliriseindhoven@gmail.com</h5>
        </div>
    </body>
  )
}

export default AdminLogin