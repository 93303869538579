import "./Navigator.css"
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'
import { Outlet } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState, useRef } from "react";

import useIsMobile from "../config/useIsMobile";

import DropdownDisplay from "./DropdownDisplay";

import { useTranslation } from "react-i18next";

function Navigator(){
  const isMobile = useIsMobile();
  const [t, i18n] = useTranslation("global")

  useEffect(() =>{  // prevents navbar from following viewport on mobile displays
    const navbar = document.getElementById("Navbar-itself")
    if(isMobile){
      navbar.classList.toggle("navbar-mobile");
      // console.log("is Mobile")
    }
    else{
      navbar.classList.toggle("navbar-mobile");
      // console.log("Not mobile")
    }

  }, [isMobile])


  function dropDownToggleBandaid(){ // only allows toggling services dropdown if it's mobile sized
    if(!isMobile){
      window.location.href = '/prices'
    }
  }

  function handleLanguageChange(lang){
    i18n.changeLanguage(lang);
  }

  // Haptic feedback for language selection buttons
  // DUMB
  useEffect(() =>{
    const buttonEN = document.getElementById('en');
    buttonEN.addEventListener('click', function() {
      buttonEN.classList.add('clicked');
    });
    buttonEN.addEventListener('animationend', function() {
      buttonEN.classList.remove('clicked');
    });
  
    const buttonNL = document.getElementById('nl');
    buttonNL.addEventListener('click', function() {
      buttonNL.classList.add('clicked');
    });
    buttonNL.addEventListener('animationend', function() {
      buttonNL.classList.remove('clicked');
    });

  }, [])

  return (
    <>
      <Navbar expand="lg" className="fixed-top navig " id="Navbar-itself">
        <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbar-mobile-toggle" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" style={styles.text}>
              <LinkContainer to="/">
                <Nav.Link className="hover-underline">{t("navbar.home")}</Nav.Link>
              </LinkContainer>

              <NavDropdown 
              onToggle={dropDownToggleBandaid} // only allows toggling services dropdown if it's mobile sized
              title="Services" 
              renderMenuOnMount={true} 
              id="basic-nav-dropdown" size="lg" 
              className="hover-underline animTest">
                <DropdownDisplay className="dropAnim"/>
              </NavDropdown>
        

              <LinkContainer to="/prices">
                <Nav.Link className="hover-underline">{t("navbar.prices")}</Nav.Link>
              </LinkContainer>

              {/* <LinkContainer to="/booking">
                <Nav.Link className="hover-underline">{t("navbar.booking")}</Nav.Link>
              </LinkContainer> */}

              <Nav className="end-50" style={styles.text}>
                <LinkContainer to="/about">
                  <Nav.Link className="hover-underline">{t("navbar.about")}</Nav.Link>
                </LinkContainer>
              </Nav>
            </Nav>
          </Navbar.Collapse>
              <div className="languageSwitcher ">
                <button className="btn" onClick={() => {handleLanguageChange("en")}} ><svg xmlns="http://www.w3.org/2000/svg" width="33" height="24" viewBox="0 0 24 24" id="en"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg><img src={require("../images/languages/EN.png")} width={"32px"}/></button>
                <button className="btn" onClick={() => {handleLanguageChange("nl")}} ><svg xmlns="http://www.w3.org/2000/svg" width="33" height="24" viewBox="0 0 24 24" id="nl"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg><img src={require("../images/languages/NL.png")} width={"32px"}/></button>
              </div>
        </Container>

        {/* <Nav className="end-50" style={Object.assign(styles.text, styles.about)}> {/*Object.assign combines both styles, but randomly breaks the website??*/}
        
      

      </Navbar>
      <Outlet/>
    </>
    );
  }



export default Navigator;

const styles={
  about:{
    paddingRight: "1%",
  },

  text:{
    fontFamily: "Jost",
  }
}




/*
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
*/