import './Footer.css'
import cardLogo from "../images/card.png"

import { Facebook } from 'react-bootstrap-icons';
import { Instagram } from 'react-bootstrap-icons';
import { Tiktok } from 'react-bootstrap-icons';

import { useTranslation } from "react-i18next";

// MAIN
function Footer(){
    const [t, i18n] = useTranslation("global")

    return(
        <footer className="flex-shrink-0 py-4 bg-dark text-white-50 border-top border-warning-subtle footer">
            <section className="footerClass row"> 
                <div className='short-about col text-left'>
                    <div className='row'>
                        <img src={cardLogo} alt="..."className="rounded-pill about-image p-2 mx-3 col" />
                        <p className='pl-5 col align-self-center mx-3'>{t("footer.description")}</p>
                    </div>
                    <div>

                    </div>
                </div>

                <div className='quick-links col text-end '>
                    <p className='px-2'>{t("footer.qLinks")}</p>
                    <ul>
                        
                        {/* <li><a href='/booking' className='px-2'>{t("footer.booking")}</a></li> */}
                        <li><a href='/prices' className='px-2'>{t("footer.prices")}</a></li>
                        <li><a href='/about' className='px-2'>{t("footer.about")}</a></li>
                    </ul>
                
                    <div className='socials px-1 container '>
                        <a href='https://www.tiktok.com/@r.e.n.a.s.1987' target='_blank' id='facebook'>
                        <Facebook width="32" height="32" className='mx-2' id='facebook'/>
                        </a>

                        <a href='https://www.instagram.com/renas.barbershop1' target='_blank' id='instagram'>
                        <Instagram width="32" height="32" className='mx-2'/>
                        </a>

                        <a href='https://www.tiktok.com/@r.e.n.a.s.1987' target='_blank' id='tiktok'>
                        <Tiktok width="32" height="32" className='mx-2'/>
                        </a>

                    </div>
                    <p className='pt-5 user-select-all'>KestrelP Dev, Hoefbladlaan 44, Arnhem</p>
                </div>  
                
            </section>
        </footer>
    )
}

export default Footer;
