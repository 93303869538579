import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

import Navigator from './Navigator';
import Prices from '../pages/Prices.js';
import NoPage from '../pages/NoPage';
import Home from '../pages/Home';
import About from '../pages/About';
import Booking from '../pages/Booking';
import Admin from '../pages/Admin';
import AdminLogin from '../pages/AdminLogin';



function AnimatedRoutes({weekDays}) {
    const location = useLocation();

  return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Navigator />}>
            <Route path="/" element={<Home weekDays={weekDays}/>}/>
            <Route path="prices" element={<Prices/>} />
            <Route path="about" element={<About/>} />
            {/* <Route path="booking" element={<Booking/>} /> */}
            <Route path="admin-panel" element={<Admin weekDays={weekDays}/>} />
            <Route path="admin-login" element={<AdminLogin/>} />
            
            </Route>
            <Route path="*" element={<NoPage />} />
        </Routes>

    </AnimatePresence>
  )
}

export default AnimatedRoutes