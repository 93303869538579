import {useEffect, useState} from "react";
import "./pageStyles/Admin.css";

import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";


// QUOTED OUT CODE IS FOR SETTING SLIDER STARTING POSITIONS ACORDING TO MONDOGB DATA, DOESN'T WORK!


function RangeSlider({setItem, id, oldTime}) {
  const curTime = new Date();
  const timeMax = 24 * 60 - 1;
  const curMin = (curTime.getHours() % 12) * 60 + curTime.getMinutes();
  const [minTimeCaption, set_minTimeCaption] = useState("");
  const [maxTimeCaption, set_maxTimeCaption] = useState("");

  const handleTimeChange = (e) => {
    let h = Math.floor(e.minValue / 60);
    let m = e.minValue % 60;
    let minH = h.toString().padStart(2, "0");
    let minM = m.toString().padStart(2, "0");
    set_minTimeCaption(minH + ":" + minM);

    let hh = Math.floor(e.maxValue / 60);
    let mm = e.maxValue % 60;
    let maxH = hh.toString().padStart(2, "0");
    let maxM = mm.toString().padStart(2, "0");
    set_maxTimeCaption(maxH + ":" + maxM);

   
    //return values from child to global Admin.js
    //console.log(minTimeCaption + " " + maxTimeCaption, id);
    


  };

  useEffect(() =>{
    setItem((prev) => {
      return {
        ...prev,
        "id": id,
        "start": minTimeCaption,
        "end": maxTimeCaption
      }
    })
  }, [minTimeCaption, maxTimeCaption])

  const getTimeLabels = () => {
    let arr = [];
    for (let i = 0; i <= 24; i++) {
      arr.push(i.toString().padStart(2, "0") + ":00");
    }
    return arr;
  };
  return (
    <div className="slider">
      <div className="multi-range-slider-container">
        <MultiRangeSlider
          labels={getTimeLabels()}
          min={0}
          max={timeMax}
          minValue={0}
          maxValue={timeMax}
          step={15}
          subSteps={true}
          stepOnly={true}
          minCaption={minTimeCaption}
          maxCaption={maxTimeCaption}
          onInput={handleTimeChange}
        />
      </div>
    </div>
  );
}





// MAIN
function Admin({weekDays}){
    const [items, setItems] = useState([
        {"id": 0, start: "0", end: "0" },
        {"id": 1, start: "0", end: "0" },
        {"id": 2, start: "0", end: "0" },
        {"id": 3, start: "0", end: "0" },
        {"id": 4, start: "0", end: "0" },
        {"id": 5, start: "0", end: "0" },
        {"id": 6, start: "0", end: "0" }
    ]);

    const [item, setItem] = useState({"id": 0, "start": "0", "end": "0" });
    // <MultiRangeSlider "min" "max"> are the starting slider positions, this code is for setting them according to previous mongoDB records. Doesn't work 
    // const [oldTime, setOldTime] = useState(null); // dates grabbed from mongoDB to put sliders in correct positions

    const [verify, setVerify] = useState(false);

    async function handleSubmit(event){
      event.preventDefault();
      // console.log(items);
      await fetch("/api",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "x-access-token": sessionStorage.getItem("token"),
        },
        url: `http://localhost:5000/api`,
        method: "POST",
        mode: "cors",
        body: JSON.stringify(items)
      })
      alert("Opening Tmes Changed!")
    }

    // updating global array of opening times
    useEffect(() =>{
      setItems(
        items.map((day) =>
          day.id === item.id
          ?{...day, start: item.start, end: item.end}
          : {...day}
        )
      )
      
      // console.log(items)
    }, [item])

    // <MultiRangeSlider "min" "max"> are the starting slider positions, this code is for setting them according to previous mongoDB records. Doesn't work 
    // useEffect(() =>{
    //   if(weekDays != undefined){
    //     setOldTime([...weekDays]);
    //     console.log(oldTime);
    //   }
    // }, [weekDays])


    // check if users token is valid upon page entry, else hide admin controls
    useEffect(() =>{
      async function verifyUserToken(){
        const response = await fetch("/api/loginVerify",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "x-access-token": sessionStorage.getItem("token"),
          },
          url: `http://localhost:5000/api/loginVerify`,
          method: "POST",
          mode: "cors",
          
        });
        const result = await response.json();
        if(result.status === "ok"){
          setVerify(true);
          return 0;
        }
        setVerify(false);
        sessionStorage.removeItem("token");
        window.location.href = "/admin-login";
        alert("Not logged in!")
      }

      verifyUserToken().catch((error) => {
        // console.log(error)
        sessionStorage.removeItem("token");
        window.location.href = "/admin-login";
        alert("Not logged in!")
      })
      // console.log(verify);
    }, [verify])


    return(
        <body style={styles.body}>
            <p><strong>TIP:</strong> set to "CLOSED" by changing the opening time for the day to "0 - 23:59"!</p>
            <form className="form-group column" id="timeEdit" onSubmit={handleSubmit}>
              <div className="pt-5 text-center">
                Monday
                <RangeSlider item={item} setItem={setItem} id={0}/>
                <div className="text-center">
                {items[0].start + " " + items[0].end}
                </div>
              </div>

              <div className="pt-5 text-center">
                Tuesday
                <RangeSlider item={item} setItem={setItem} id={1}/>
                <div className="text-center">
                {items[1].start + " " + items[1].end}
                </div>
              </div>

              <div className="pt-5 text-center">
                Wednesday
                <RangeSlider item={item} setItem={setItem} id={2}/>
                <div className="text-center">
                {items[2].start + " " + items[2].end}
                </div>
              </div>

              <div className="pt-5 text-center">
                Thursday
                <RangeSlider item={item} setItem={setItem} id={3}/>
                <div className="text-center">
                {items[3].start + " " + items[3].end}
                </div>
              </div>

              <div className="pt-5 text-center">
                Friday
                <RangeSlider item={item} setItem={setItem} id={4}/>
                <div className="text-center">
                {items[4].start + " " + items[4].end}
                </div>
              </div>

              <div className="pt-5 text-center">
                Saturday
                <RangeSlider item={item} setItem={setItem} id={5}/>
                <div className="text-center">
                {items[5].start + " " + items[5].end}
                </div>
              </div>

              <div className="pt-5 text-center">
                Sunday
                <RangeSlider item={item} setItem={setItem} id={6}/>
                <div className="text-center">
                {items[6].start + " " + items[6].end}
                </div>
              </div>

                <input type="submit" className="btn btn-primary p-3 m-4"></input>
            </form>
            
        </body>
    )
}

export default Admin;

const styles={
    body:{
        paddingTop: 60,
    }
}



// Set starting values from currently recorded mongoDB/ json file