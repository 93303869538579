import "./pageStyles/Prices.css"

import { motion } from "framer-motion"; 

import {Scissors} from 'react-bootstrap-icons';

import { useTranslation } from "react-i18next";

function Scissorsx(){   // reducing prop spam
    return(
        <Scissors color="red" transform="rotate(45)" className="icon"/>
    )
}

function Dividerx(){    // horizontal rule/ divider
    return(
        <hr className="mx-5 " />
    )
}

// MAIN
function Prices(){
    const [t, i18n] = useTranslation("global")


    return(
        <motion.body 
        style={styles.body} 
        className="container justify-content-center flex-direction-column align-items-center bg-dark"
        
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1, transition: {duration: 0.5}}}
        exit={{x: window.innerWidth, opacity: 0, transition: {duration:  0.1}}}
        >
            <div className="item ">
                <div className="titlePrice">
                    <span className="treatment">{t("prices.hairColouring.title")}</span>
                    <span className="price">75$</span>
                </div>
                <section className="description">
                    <p className="descriptionText">{t("prices.hairColouring.description")}</p>
                    <ul className="list-group">
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.hairColouring.item1")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.hairColouring.item2")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.hairColouring.item3")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.hairColouring.item4")}</span>
                        </li>
                    </ul>
                </section>
            </div>
            <Dividerx />

            <div className="item">
                <div className="titlePrice">
                    <span className="treatment">{t("prices.haircuts.title")}</span>
                    <span className="price">50$</span>
                </div>
                <section className="description">
                    <p className="descriptionText">{t("prices.haircuts.description")}</p>
                    <ul className="list-group">
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.haircuts.item1")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.haircuts.item2")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.haircuts.item3")}</span>
                        </li>
                    </ul>
                </section>
            </div>
            <Dividerx />

            <div className="item">
                <div className="titlePrice">
                    <span className="treatment">{t("prices.beardTrimming.title")}</span>
                    <span className="price">35$</span>
                </div>
                <section className="description">
                    <p className="descriptionText">{t("prices.beardTrimming.description")}</p>
                    <ul className="list-group">
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.beardTrimming.item1")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.beardTrimming.item2")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.beardTrimming.item3")}</span>
                        </li>
                    </ul>
                </section>
            </div>
            <Dividerx />
            <div className="item mb-5">
                <div className="titlePrice">
                    <span className="treatment">{t("prices.eyebrows.title")}</span>
                    <span className="price">15$</span>
                </div>
                <section className="description">
                    <p className="descriptionText">{t("prices.eyebrows.description")}</p>
                    <ul className="list-group">
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.eyebrows.item1")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.eyebrows.item2")}</span>
                        </li>
                        <li>
                            <Scissorsx/>
                            <span>{t("prices.eyebrows.item3")}</span>
                        </li>
                    </ul>
                    
                </section>
            </div>



        </motion.body>
    )
}

export default Prices;

const styles={
    body:{
        paddingTop: 60,
        //paddingLeft: 200,
    }
}